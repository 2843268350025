import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import TextInput from '../components/TextInput';
import CustomButton from '../components/Button';
import { login } from '../services/authService';
import { Container, Grid, Paper, Typography, Alert } from '@mui/material';
import { styled } from '@mui/system';
import logo from '../assets/ld_logo.svg'; 

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
}));

const StyledTextInput = styled(TextInput)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const StyledLink = styled(Link)({
  marginTop: '16px',
  color: 'grey',
  textDecoration: 'none',
});

const StyledGridContainer = styled(Grid)({
  justifyContent: 'start',
  marginTop: '10px',
});

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login(email, password);
      localStorage.setItem('token', response.data.token);
      navigate('/dashboard');
    } catch (err) {
      setError('Invalid email or password');
      console.error('Login failed', err);
    }
  };

  return (
    <StyledContainer component="main" maxWidth="xs">
      <StyledPaper className="login-container" elevation={3}>
      <div className='login-logo-container'><img className="login-logo" src={logo} alt="Logo" /> </div>
        <Typography component="h1" variant="h5" align="center">
          Login
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        <form onSubmit={handleSubmit}>
          <StyledTextInput
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="login-email-input"
          />
          <StyledTextInput
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-password-input"
          />
          <CustomButton type="submit" className="login-submit-button">Login</CustomButton>
          <StyledGridContainer container justifyContent="flex-end">
            <Grid item>
              <StyledLink className="signup-text" to="/forgot-password">Forgot password?</StyledLink>
              <StyledLink className="signup-link" to="/signup">Don’t have an Account? <span>Click here to Register</span></StyledLink>
            </Grid>
          </StyledGridContainer>
        </form>
      </StyledPaper>
    </StyledContainer>
  );
};

export default Login;