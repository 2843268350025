import React, { useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import TextInput from '../components/TextInput';
import { resetPassword } from '../services/authService';
import { Paper, Container, Typography, Alert, Grid, Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(8),
}));

const StyledTextInput = styled(TextInput)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const StyledLink = styled(Link)({
  marginTop: '16px',
  color: 'grey',
  textDecoration: 'none',
});

const StyledGridContainer = styled(Grid)({
  justifyContent: 'start',
  marginTop: '10px',
});

const ResetButton = styled(Button)({
  marginTop: '6px',
  backgroundColor: '#1A1E31',
  '&:hover': {
    backgroundColor: '#8998e1;',
  },
});

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      await resetPassword(token, password);
      setSuccess('Password has been reset');
      setTimeout(() => navigate('/login'), 2000);
    } catch (err) {
      setError('Failed to reset password');
      console.error('Failed to reset password', err);
    }
  };

  return (
    <StyledContainer component="main" maxWidth="xs">
      <StyledPaper elevation={3}>
        <Typography component="h1" variant="h5" align="center">
          Reset Password
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <form onSubmit={handleSubmit}>
          <StyledTextInput
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
            required
            className="reset-password-password-input"
          />
          <StyledTextInput
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
            required
            className="reset-password-confirm-password-input"
          />
          <ResetButton variant="contained" color="primary" type="submit" fullWidth className="reset-password-submit-button">Reset Password</ResetButton>
        </form>
        <StyledGridContainer container justifyContent="flex-end">
          <Grid item>
            <StyledLink to="/login">Back to Login</StyledLink>
          </Grid>
        </StyledGridContainer>
      </StyledPaper>
    </StyledContainer>
  );
};

export default ResetPassword;
