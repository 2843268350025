import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import TextInput from '../components/TextInput';
import { forgotPassword } from '../services/authService';
import { Paper, Container, Typography, Alert, Grid, Button } from '@mui/material';
import { styled } from '@mui/system';


const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(8),
}));

const StyledTextInput = styled(TextInput)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));

const StyledLink = styled(Link)({
  marginTop: '16px',
  color: 'grey',
  textDecoration: 'none',
});

const StyledGridContainer = styled(Grid)({
  justifyContent: 'start',
  marginTop: '10px',
});

const MyButton = styled(Button)({
  marginTop: '6px',
  marginRight: '6px',
  backgroundColor: '#1A1E31',
  '&:hover': {
    backgroundColor: '#8998e1;',
  },
});

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await forgotPassword(email);
      setSuccess('Password reset link sent to your email');
      setTimeout(() => navigate('/login'), 2000);
    } catch (err) {
      setError('Failed to send password reset link');
      console.error('Failed to send password reset link', err);
    }
  };

  return (
    <StyledContainer component="main" maxWidth="xs">
      <StyledPaper elevation={3}>
        <Typography component="h1" variant="h5" align="center">
          Forgot Password
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {success && <Alert severity="success">{success}</Alert>}
        <form onSubmit={handleSubmit}>
          <StyledTextInput
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="forgot-password-email-input"
          />
          <MyButton variant="contained" type="submit" className="forgot-password-submit-button">Send Reset Link</MyButton>
          <StyledGridContainer container justifyContent="flex-end">
            <Grid item>
              <StyledLink to="/login">Back to Login</StyledLink>
            </Grid>
          </StyledGridContainer>
        </form>
      </StyledPaper>
    </StyledContainer>
  );
};

export default ForgotPassword;
