import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TextInput from "../components/TextInput";
import { signup, fetchCaptcha } from "../services/authService";
import { Container, Paper, Typography, Alert, Button } from "@mui/material";
import { styled } from "@mui/system";

const StyledContainer = styled(Container)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
});
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  marginTop: theme.spacing(8),
}));
const StyledTextInput = styled(TextInput)(({ theme }) => ({
  margin: theme.spacing(1, 0),
}));
const MyButton = styled(Button)({
  marginTop: "16px",
  marginRight: "6px",
  backgroundColor: "#1A1E31",
  "&:hover": {
    backgroundColor: "#8998e1;",
  },
});

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [captchaImage, setCaptchaImage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const navigate = useNavigate();



  // Make sure the CAPTCHA is only loaded once when the component mounts
  useEffect(() => {
    console.log('Signup component mounted at', new Date().toISOString());
    let isMounted = true;
    const loadCaptcha = async () => {
      try {
        const response = await fetchCaptcha();
        if (isMounted) {
          setCaptchaImage(response.data);
        }
      } catch (err) {
        console.error("Failed to load CAPTCHA", err);
      }
    };

    loadCaptcha();

    return () => {
      isMounted = false;
      console.log('Signup component unmounted at', new Date().toISOString());
    };
  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    try {
      await signup(email, password, captcha); ///SIGNUP
      setSuccess("Signup successful. Redirecting to login...");
      setTimeout(() => navigate("/login"), 2000);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.errors) {
        const serverErrors = err.response.data.errors
          .map((error) => error.msg)
          .join(", ");
        setError(`Signup failed: ${serverErrors}`);
      } else if (err.response && err.response.data) {
        setError(`Signup failed: ${JSON.stringify(err.response.data)}`);
      } else {
        setError("Signup failed");
      }
      console.error("Signup failed", err);
      // Reload CAPTCHA on error
      // loadCaptcha();
    }
  };



  const handleGoBack = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  return (
    <StyledContainer component="main" maxWidth="xs">
      <StyledPaper elevation={3}>
        <Typography component="h1" variant="h5" align="center">
          Signup
        </Typography>

        <form onSubmit={handleSubmit}>
          <StyledTextInput
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="signup-email-input"
          />
          <StyledTextInput
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="signup-password-input"
          />
          <StyledTextInput
            label="Confirm Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="signup-confirm-password-input"
          />
          <div dangerouslySetInnerHTML={{ __html: captchaImage }} />
          <StyledTextInput
            label="Captcha"
            value={captcha}
            onChange={(e) => setCaptcha(e.target.value)}
            className="signup-captcha-input"
          />
          <MyButton
            variant="contained"
            type="submit"
            className="signup-submit-button"
          >
            Signup
          </MyButton>
          <MyButton
            variant="contained"
            type="button"
            className="go-back-button"
            onClick={handleGoBack}
          >
            Go Back
          </MyButton>
        </form>
        {error && <Alert className="signup-alert" severity="error">{error}</Alert>}
        {success && <Alert className="signup-alert" severity="success">{success}</Alert>}
      </StyledPaper>
    </StyledContainer>
  );
};

export default Signup;
